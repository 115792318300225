.footer {
  background-color: #050a2c;
  padding-top: 128px;
  padding-bottom: 40px;
  position: relative;

  &-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 980px;
    margin: 0 auto 76px;
    gap: 24px;

    &__top {
      font-family: "SpaceMono", sans-serif;
      font-size: 18px;
      line-height: 24px;

      color: #f5e9e1;
    }
    &__title {
      font-family: "SpaceMono", sans-serif;
      font-weight: 700;
      font-size: 80px;
      line-height: 100px;

      text-align: center;
      background: -webkit-linear-gradient(360deg, rgba(244, 236, 228, 1), rgba(255, 99, 97, 1));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;
      width: fit-content;
    }
  }
  &-list {
    display: flex;
    justify-content: center;
    margin-bottom: 106px;

    &__content {
      a {
        width: 100%;
      }
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    max-width: 1032px;
    margin: 0 auto 90px;

    p {
      font-size: 16px;
      line-height: 32px;

      text-align: center;
      color: #f5e9e1;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }
  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 16px;
      line-height: 32px;

      color: #F5E9E1;
    }
    span > span {
      opacity: 0.5;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    user-select: none;
    pointer-events: none;

    img {
      height: 100%;
      width: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  @media (max-width: 1023.9px) {
    padding-top: 90px;

    &-list {
      margin-bottom: 80px;

      &__content {
        --statu-gutter-y: 24px;
        --statu-gutter-x: 24px;
      }
    }

    &-heading {
      max-width: 800px;

      &__title {
        font-family: "SpaceMono", sans-serif;
        font-weight: 700;
        font-size: 64px;
        line-height: 80px;
      }
    }
  }
  @media (max-width: 767.9px) {
    &-heading {
      max-width: 600px;

      &__title {
        font-size: 48px;
        line-height: 64px;
      }
    }
    &-content {
      margin-bottom: 60px;
    }
    &-bottom {
      flex-direction: column;
      justify-content: center;
      text-align: center;

      span {
        font-size: 16px;
        line-height: 32px;

        color: #F5E9E1;
      }
      span > span {
        opacity: 0.5;
      }
    }
  }
  @media (max-width: 639.9px) {
    &-heading {
      max-width: 400px;
      margin-bottom: 32px;
      order: 1;

      &__title {
        font-size: 32px;
        line-height: 48px;
      }
    }
    &-content {
      margin-bottom: 32px;
      order: 2;

      p {
        font-size: 14px;
        line-height: 24px;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
    &-list {
      margin-bottom: 60px;
      order: 3;
    }
    &-bottom {
      order: 4;
    }
    &__background {
      top: -300px;
    }
  }
}
