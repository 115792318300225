.modal {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
  opacity: 0;
  visibility: hidden;
  user-select: none;
  pointer-events: none;
  transition: all 200ms;
  overflow: auto;
  padding: 24px;

  &::-webkit-scrollbar {
    display: none;
  }

  &-dialog {
    position: relative;
    z-index: 12;
    width: 100%;
    max-width: 764px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    background: #fff;
    border-radius: 16px;
    margin: auto;
  }
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(#000, 0.5);
    z-index: 1;
    display: flex;
  }

  &-heading {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    &__button {
      height: 1em;
      width: 1em;
      font-size: 28px;
      color: #0b2330;
    }
  }
  &-content {
    width: 100%;
  }
  &-footer {
    width: 100%;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    user-select: auto;
    pointer-events: auto;
  }

  @media (max-width: 767.9px) {
    padding: 20px;

    &-dialog {
      padding: 16px;
      gap: 24px;
    }
    &-heading {
      &__button {
        font-size: 24px;
      }
    }
  }
}

//Components
.modal-source {
  padding: 18px 24px;
  box-shadow: 0 0 0 2px #e2e2e2;
  display: flex;
  align-items: center;
  border-radius: 8px;
  gap: 16px;

  &__logo {
    border-radius: 50%;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    pointer-events: none;

    img {
      height: 100%;
      width: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    color: #0b2330;
    font-family: "SpaceMono", sans-serif;
    position: relative;
    margin-right: auto;
    padding-right: 28px;
    min-width: 100px;

    &-shorty {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-transform: uppercase;
    }
    &-name {
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
    }

    select {
      position: absolute;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: "";
      text-transform: uppercase;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.4 7.83333L10.8 7.83333L10.8 4.16667L14.4 4.16667L14.4 7.83333ZM14.4 0.500001L18 0.500001L18 4.16667L14.4 4.16667L14.4 0.500001ZM7.2 4.16667L7.2 7.83333L3.6 7.83333L3.6 4.16667L7.2 4.16667ZM7.2 11.5L7.2 7.83333L10.8 7.83333L10.8 11.5L7.2 11.5ZM3.6 4.16667L-1.60275e-07 4.16667L0 0.5L3.6 0.5L3.6 4.16667Z' fill='%230B2330'/%3E%3C/svg%3E%0A");
      background-position: right center;
      background-repeat: no-repeat;
      background-size: 18px 11px;

      option {
        font-size: 16px;
      }

      &:focus {
        outline: none;
        border: none;
      }
    }
  }
  &__value {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    flex: 1;
    color: #0b2330;
    font-family: "SpaceMono", sans-serif;

    span {
      font-size: 24px;
      line-height: 32px;
    }
    input {
      max-width: 120px;
      width: 100%;
      text-align: right;
      font-size: 24px;
      line-height: 32px;
      outline: none;
      border: none;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }
  }

  @media (max-width: 767.9px) {
    padding: 16px;
    gap: 12px;

    &__logo {
      height: 40px;
      width: 40px;
    }
    &__content {
      &-shorty {
        font-size: 18px;
        line-height: 24px;
      }
      &-name {
        font-size: 13px;
        line-height: 18px;
      }
    }
    &__value {
      input,
      span {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  @media (max-width: 639.9px) {
    display: grid;
    grid-template-columns: 42px auto;

    &__logo {
      height: 42px;
      width: 42px;
    }
    &__value {
      grid-area: 2 / span 2;

      span {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}
.modal-characters {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow: auto;
  gap: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  &__item {
    width: 120px;
    background: #f7f7f7;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    img {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      height: 56%;
      width: fit-content;
      object-fit: contain;
      object-position: center;
      user-select: none;
      pointer-events: none;
      z-index: 1;
    }

    &-placeholder {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      height: 100%;
      width: 100%;
      background: #d9d9d9;
    }

    &::after {
      content: "";
      display: flex;
      padding-top: 100%;
    }

    &-effect {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: all 300ms;
      height: 100%;
      width: 100%;
      user-select: none;
      pointer-events: none;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        user-select: none;
        pointer-events: none;
        background: linear-gradient(360deg, rgba(183, 103, 227, 0.2) 0%, #f7f7f7 100%);
      }
      &::after {
        content: "";
        height: 22px;
        width: 22px;
        position: absolute;
        top: 10px;
        left: 10px;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.8024 0H9.60473V1.12223H8.39921V2.24446H7.20158V3.37408H5.99606V4.49631H4.79842V5.61854H3.60079V4.49631H2.39527V3.37408H1.19764V4.49631H0V5.61854H1.19764V6.74815H2.39527V7.87039H3.60079V9H4.79842V7.87039H5.99606V6.74815H7.20158V5.61854H8.39921V4.49631H9.60473V3.37408H10.8024V2.24446H12V1.12223H10.8024V0Z' fill='black'/%3E%3C/svg%3E%0A");
        background-size: 10px 12px;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        background-color: #CFFF8F;
      }
    }

    &:not(.active) {
      &::before {
        content: "BUY";
        padding: 6px 16px;
        opacity: 0;
        transition: all 300ms;
        color: #0B2330;
        font-size: 14px;
        font-family: "SpaceMono", sans-serif;
        line-height: 18px;
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: fit-content;
        height: fit-content;
        z-index: 1;
        background: #CFFF8F;
        border-radius: 60px;
      }

      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }

    &.selected & {
      &-effect {
        opacity: 1;
      }
    }
  }

  @media (max-width: 1023.9px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    &__item {
      width: 100%;
    }
  }
  @media (max-width: 767.9px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 639.9px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.modal-select {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 24px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 48px;
    width: 48px;
    user-select: none;
    pointer-events: none;

    img {
      height: 100%;
      width: 100%;
      object-position: center;
      object-fit: contain;
    }
  }
  select {
    font-weight: 700;
    font-family: "SpaceMono", sans-serif;
    font-size: 18px;
    line-height: 24px;

    color: #0B2330;
    width: 100%;
    height: 80px;
    outline: none;
    border-radius: 8px;
    border: 2px solid #0B2330;
    padding: 15px 57px 15px 87px;
    background-color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    text-transform: uppercase;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.4 7.83333L10.8 7.83333L10.8 4.16667L14.4 4.16667L14.4 7.83333ZM14.4 0.500001L18 0.500001L18 4.16667L14.4 4.16667L14.4 0.500001ZM7.2 4.16667L7.2 7.83333L3.6 7.83333L3.6 4.16667L7.2 4.16667ZM7.2 11.5L7.2 7.83333L10.8 7.83333L10.8 11.5L7.2 11.5ZM3.6 4.16667L-1.60275e-07 4.16667L0 0.5L3.6 0.5L3.6 4.16667Z' fill='%230B2330'/%3E%3C/svg%3E%0A");
    background-position: right 24px center;
    background-repeat: no-repeat;
    background-size: 18px 11px;

    option {
      text-transform: uppercase;
    }
  }

  @screen lt-md {
    &__logo {
      left: 16px;
      height: 40px;
      width: 40px;
    }
    select {
      padding: 15px 50px 15px 64px;
      height: 74px;
      background-position: right 16px center;
    }
  }
}
