.home {
  display: flex;
  flex-direction: column;

  &__content {
    position: relative;

    &::before {
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      display: flex;
      height: 100%;
      max-height: 3000px;
      width: 100%;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #be6ae5 100%);
      z-index: -1;
      user-select: none;
      pointer-events: none;
    }
  }
}
.home-heading {
  display: flex;
  flex-direction: column;
  color: #0b2330;
}
.home-hero {
  position: relative;
  padding-top: 190px;
  overflow: hidden;
  padding-bottom: 72px;
  background-color: #050a2c;
  cursor: url("/assets/images/cursor.png"), auto;

  &__container {
    display: flex;
    flex-direction: column;
    min-height: calc(1118px - 190px - 72px);
    height: calc(100vh - 190px - 72px);
    position: relative;
  }
  &__heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 5;

    &-title {
      --bg-size: 400%;
      font-size: 158px;
      line-height: 234px;
      font-family: "SpaceMono", sans-serif;
      font-weight: 700;
      background: linear-gradient(90deg, rgba(244, 236, 228, 1), rgba(255, 99, 97, 1), rgba(244, 236, 228, 1)) 0 0 /
        var(--bg-size) 100%;
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      animation: move-bg 40s infinite linear;

      @media (prefers-reduced-motion: no-preference) {
        & {
          animation: move-bg 40s linear infinite;
        }
        @keyframes move-bg {
          to {
            background-position: var(--bg-size) 0;
          }
        }
      }
    }
    &-text {
      max-width: 920px;
      color: #f5e9e1;
      text-align: center;
      font-size: 24px;
      line-height: 36px;
    }
  }
  &__actions {
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    position: relative;
    z-index: 5;
  }
  &__image {
    height: 938px;
    position: absolute;
    top: 142px;
    bottom: -220px;
    left: 0;
    right: 38px;
    width: 1526px;
    z-index: 1;
    margin: auto;
    user-select: none;
    pointer-events: none;

    &-planet {
      position: absolute;
      top: -26px;
      left: 0;
      user-select: none;
      pointer-events: none;
      width: 487px;
      height: 460px;
      z-index: 0;

      img {
        height: 100%;
        width: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
    &-line {
      width: 1125px;
      height: 615px;
      position: absolute;
      top: 92px;
      left: 142px;
      z-index: 1;

      img {
        position: absolute;
        object-fit: contain;
        object-position: center;
        height: var(--height);
        width: var(--width);
        left: var(--left);
        top: var(--top);
        bottom: var(--bottom);
        right: var(--right);
      }
    }
    &-star {
      z-index: 2;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 650px;
      width: 650px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        position: absolute;
        object-fit: contain;
        object-position: center;
        height: var(--height);
        width: var(--width);
        left: var(--left);
        top: var(--top);
        bottom: var(--bottom);
        right: var(--right);
      }
    }
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 106px;
    top: 348px;
    height: 122px;
    width: 122px;
    z-index: 4;

    &-icon {
      width: 48px;
      height: 48px;
    }
    &-texts {
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      animation: spin 10s linear infinite;
    }

    img {
      position: absolute;
      object-position: center;
      object-fit: contain;
      user-select: none;
      pointer-events: none;
    }

    &:hover {
      cursor: url("/assets/images/cursor.png"), auto !important;
    }

    @keyframes spin {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
  &__background {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
    pointer-events: none;
    user-select: none;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  a:hover,
  input:hover,
  button:hover {
    cursor: url("/assets/images/cursor-hover.png") 10 0, auto;
  }

  @media (max-width: 1630px) {
    &__container {
      min-height: calc(1000px - 190px - 72px);
    }
    &__heading {
      &-title {
        font-size: 120px;
        line-height: 180px;
      }
    }
    &__image {
      transform: scale(0.9);
      transform-origin: left top;
    }
    &__link {
      right: 60px;
    }
  }
  @media (max-width: 1280px) {
    padding-top: 120px;

    &__container {
      min-height: 620px;
      height: calc(100vh - 190px - 72px);
    }
    &__link {
      top: 280px;
      right: 0;
    }
    &__heading {
      &-title {
        font-size: 90px;
        line-height: 130px;
      }
      &-text {
        max-width: 800px;
        font-size: 20px;
        line-height: 24px;
      }
    }
    &__image {
      transform: scale(0.8);
      left: -120px;
    }
  }
  @media (max-width: 1024px) {
    &__link {
      top: 240px;
      right: 20px;
    }
    &__heading {
      gap: 8px;

      &-title {
        font-size: 74px;
        line-height: 100px;
      }
      &-text {
        font-size: 20px;
        line-height: 24px;
      }
    }
    &__image {
      transform: scale(0.7);
    }
  }
  @media (max-width: 768px) {
    padding-bottom: 48px;

    &__container {
      height: calc(100vh - 190px - 48px);
    }
    &__actions {
      flex-direction: column;
      gap: 20px;

      & > button {
        width: 100%;
        max-width: 420px;
      }
    }
    &__link {
      display: none;
    }
    &__heading {
      &-title {
        font-size: 52px;
        line-height: 72px;
      }
      &-text {
        max-width: 720px;
        font-size: 18px;
        line-height: 20px;
      }
    }
    &__image {
      top: 100px;
      left: -60px;
      transform: scale(0.55);
    }
  }
  @media (max-width: 640px) {
    &__container {
      height: 532px;
      min-height: unset;
    }
    &__link {
      top: 220px;
      right: 20px;
    }
    &__actions {
      & > button {
        max-width: 280px;
      }
    }
    &__heading {
      &-title {
        font-size: 36px;
        line-height: 48px;
      }
      &-text {
        font-size: 14px;
        line-height: 20px;
      }
    }
    &__image {
      height: 315px;
      top: 156px;
      bottom: unset;
      left: -96px;
      right: -87px;
      width: 514px;
      transform: scale(1);

      &-planet {
        width: 164px;
        height: 156px;
        top: -10px;
      }
      &-line {
        width: 378px;
        height: 207px;
        top: 30px;
        left: 48px;

        img {
          height: var(--m-height);
          width: var(--m-width);
          left: var(--m-left);
          top: var(--m-top);
          bottom: var(--m-bottom);
          right: var(--m-right);
        }
      }
      &-star {
        height: 218px;
        width: 218px;

        img {
          position: absolute;
          object-fit: contain;
          object-position: center;
          height: var(--m-height);
          width: var(--m-width);
          left: var(--m-left);
          top: var(--m-top);
          bottom: var(--m-bottom);
          right: var(--m-right);
        }
      }
    }
  }
}
.home-bar {
  background: #6c20cc;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;

  &__inner {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 80px;
    height: 62px;
    margin-right: 80px;

    &-text {
      color: #fff;
      font-family: "SpaceMono", sans-serif;
      font-size: 36px;
      height: 54px;
      font-weight: 700;
      white-space: nowrap;
    }
    &-icon {
      flex: none;
      font-size: 48px;
      color: #fff;
    }

    &--yellow & {
      &-text {
        color: #fad12c;
      }
      &-icon {
        color: #fad12c;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(#fff, 0.2);
    user-select: none;
    pointer-events: none;
  }

  @media (max-width: 1279.9px) {
    &__item {
      gap: 40px;
      margin-right: 40px;

      &-text {
        font-size: 32px;
        height: 42px;
      }
      &-icon {
        font-size: 36px;
      }
    }
  }
  @media (max-width: 767.9px) {
    &__item {
      gap: 32px;
      height: 32px;
      margin-right: 32px;

      &-text {
        font-size: 24px;
        height: 32px;
      }
      &-icon {
        font-size: 32px;
      }
    }
  }
  @media (max-width: 639.9px) {
    &__item {
      gap: 16px;
      margin-right: 16px;

      &-text {
        font-size: 18px;
        height: 24px;
      }
      &-icon {
        font-size: 24px;
      }
    }
  }
}
.home-promotion {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 174px;
  margin-bottom: 300px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    background: #c983e9;
    user-select: none;

    &-character {
      width: 82%;
      object-fit: contain;
      z-index: 1;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &-background {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-position: center;
      object-fit: cover;
      pointer-events: none;
    }

    img {
      position: absolute;
    }
  }

  @media (max-width: 1279.9px) {
    padding-top: 100px;
    margin-bottom: 160px;

    &__list {
      flex-wrap: wrap-reverse;
    }
  }
  @media (max-width: 1023.9px) {
    margin-bottom: 100px;
  }
  @media (max-width: 639.9px) {
    padding-top: 64px;

    &__item {
      border-radius: 12px;
    }
  }
}
.home-join {
  scroll-margin: 100px;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 300px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 100px;
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 40px;

    &-content {
      display: flex;
      flex-direction: column;
      gap: 4px;
      color: #0b2330;
    }
    &-icon {
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 94px;
      width: 94px;
      position: relative;
      font-size: 48px;
      color: #0b2330;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
        z-index: -1;
        user-select: none;
      }
    }
  }

  @media (max-width: 1279.9px) {
    margin-bottom: 160px;
  }
  @media (max-width: 1023.9px) {
    &__list {
      gap: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    &__item {
      &-icon {
        height: 80px;
        width: 80px;
        font-size: 32px;
      }
    }
  }
  @media (max-width: 767.9px) {
    margin-bottom: 100px;

    &__list {
      grid-template-columns: 1fr;
    }
    &__item {
      gap: 16px;

      &-icon {
        height: 76px;
        width: 76px;
        font-size: 32px;
      }
    }
  }
}
.home-faq {
  scroll-margin: 40px;
  border-top: 1px solid #0b2330;
  border-bottom: 1px solid #0b2330;
  margin-bottom: 260px;

  &__content {
    padding-top: 140px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 118px;
    position: sticky;
    top: -100px;

    &-image {
      width: 100%;
      max-width: 488px;
      user-select: none;

      img {
        width: 100%;
        object-position: center;
        object-fit: contain;
      }
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 120px;
    padding-left: 80px;
    padding-top: 60px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 1px;
      background: #0b2330;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: #0b2330;
    min-height: 400px;
    padding-top: 48px;
    padding-bottom: 48px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -60px;
      left: -80px;
      width: calc(100% + 160px);
      height: 1px;
      background: #0b2330;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  @media (max-width: 1023.9px) {
    border: none;
    margin-bottom: 120px;

    &__list {
      padding: 0;
      gap: 0;

      &::before {
        top: 0;
        left: -20px;
        width: calc(100% + 40px);
        height: 1px;
      }
    }
    &__item {
      padding-top: 32px;
      padding-bottom: 32px;
      min-height: unset;
      gap: 16px;

      &-title {
        font-family: "SpaceMono", sans-serif;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      }

      &::after {
        width: calc(100% + 40px);
        left: -20px;
        bottom: 0;
      }

      &:last-child {
        &::after {
          display: inline;
        }
      }
    }
    &__content {
      padding-top: 60px;
      padding-bottom: 60px;
      gap: 40px;
      position: static;

      &-image {
        display: none;
      }
    }
  }
  @media (max-width: 639.9px) {
    margin-bottom: 80px;

    &__content {
      padding-top: 32px;
      padding-bottom: 32px;
      position: static;
    }
  }
}
.home-earn {
  margin-bottom: 150px;
  position: relative;

  &__list {
    display: flex;
    width: 100%;
    overflow: hidden;
    z-index: 0;

    span {
      color: #0B2330;
      font-family: "SpaceMono", sans-serif;
      font-size: 360px;
      font-weight: 700;
      margin-right: 100px;
    }
  }
  &__avatar {
    position: absolute;
    top: -40px;
    right: 300px;
    user-select: none;
    pointer-events: none;
    z-index: 1;

    img {
      width: 500px;
      object-fit: contain;
      object-position: center;
    }
  }

  @media (max-width: 1535.9px) {
    &__list {
      span {
        font-size: 240px;
        margin-right: 40px;
      }
    }
    &__avatar {
      right: 120px;
      top: -20px;

      img {
        width: 320px;
      }
    }
  }
  @media (max-width: 1023.9px) {
    margin-bottom: 120px;

    &__list {
      span {
        font-size: 160px;
      }
    }
    &__avatar {
      right: 180px;
      top: -40px;

      img {
        width: 220px;
      }
    }
  }
  @media (max-width: 767.9px) {
    &__list {
      span {
        font-size: 120px;
        margin-right: 32px;
      }
    }
    &__avatar {
      right: 140px;
      top: -20px;

      img {
        width: 160px;
      }
    }
  }
  @media (max-width: 767.9px) {
    margin-bottom: 80px;

    &__list {
      span {
        font-size: 72px;
      }
    }
    &__avatar {
      right: 90px;
      top: -40px;

      img {
        width: 120px;
      }
    }
  }
}
.home-modal {
  &__title {
    color: #0b2330;
    font-size: 32px;
    font-family: "SpaceMono", sans-serif;
    font-weight: 700;
    line-height: 40px;
  }
  &__heading {
    height: 94px;
    width: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &-icon {
      font-size: 48px;
      color: #0b2330;
      position: relative;
      z-index: 1;
    }
    &-background {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      user-select: none;
      pointer-events: none;

      img {
        object-position: center;
        object-fit: cover;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #0b2330;
  }
  &__swap {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;

      & > * {
        width: 100%;
      }
    }
    &-icon {
      width: fit-content;
      font-size: 24px;
      color: #0b2330;
    }
    &-reference {
      color: #0b2330;
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
  &__markets {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;

    &-item {
      height: 1em;
      width: 1em;
      font-size: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    gap: 40px;

    & > a {
      width: 100%;
    }
  }

  @media (max-width: 767.9px) {
    &__title {
      font-size: 24px;
      line-height: 32px;
    }
    &__actions {
      justify-content: center;
      gap: 12px;
      flex-direction: column;
    }
    &__swap {
      gap: 24px;

      &-content {
        gap: 12px;
      }
      &-icon {
        width: fit-content;
        font-size: 24px;
        color: #0b2330;
      }
      &-reference {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  @media (max-width: 639.9px) {
    &__heading {
      height: 72px;
      width: 72px;

      &-icon {
        font-size: 36px;
      }
    }
  }
}
.home-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease-out;
  opacity: 0;
  visibility: hidden;
  user-select: none;
  pointer-events: none;
  padding: 20px;
  z-index: 9;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 920px;
    width: 100%;
    position: relative;
    z-index: 2;

    img,video, iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-position: center;
      object-fit: cover;
    }

    &::before {
      content: "";
      display: flex;
      padding-top: 56.25%; // 16:9
    }
  }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.5);
  }
  &__close {
    position: absolute;
    z-index: 3;
    right: 24px;
    top: 24px;
    font-size: 32px;
    color: #fff;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    user-select: auto;
    pointer-events: auto;
  }
}