.cookie {
  position: fixed;
  left: 40px;
  bottom: 40px;
  z-index: 9;
  transition: all 200ms ease-out;

  &__modal {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    border-radius: 16px;
    background: rgba(#141414, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 100%;
    max-width: 300px;
    position: relative;
    z-index: 2;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 16px;
      padding: 2px;
      background: linear-gradient(
          134deg,
          rgba(255, 255, 255, 0.9) 0%,
          rgba(255, 255, 255, 0.36) 16.2%,
          rgba(255, 255, 255, 0) 85.55%,
          rgba(255, 255, 255, 0.9) 100%
        ),
        rgba(255, 255, 255, 0.08);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      pointer-events: none;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #fff;

    a {
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      text-decoration: underline;
      width: fit-content;
    }
  }
  &__text {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__heading {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    &-title {
      font-size: 20px;
      line-height: 32px;

      color: #fff;
    }
    &-button {
      height: 1em;
      width: 1em;
      font-size: 16px;
      color: #fff;
      margin-top: 6px;
    }
  }

  &.close {
    opacity: 0;
    visibility: hidden;
    user-select: none;
    pointer-events: none;
  }

  @media (max-width: 1279.9px) {
    left: 24px;
    bottom: 24px;
  }
  @media (max-width: 1023.9px) {
    left: 16px;
    right: 16px;
    bottom: 16px;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: #fff;

      a {
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        text-decoration: underline;
        width: fit-content;
      }
    }
    &__text {
      color: #fff;
      font-size: 13px;
      line-height: 16px;
    }
    &__modal {
      padding: 14px;
      max-width: unset;
      gap: 10px;
    }
  }
}
