.header {
  max-width: 1300px;
  margin: auto;
  padding: 16px;
  z-index: 10;
  border-radius: 60px;
  background: rgba(#fff, 0.08);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 24px;

  &__logo {
    width: 140px;
    height: 48px;
    display: flex;

    img {
      height: 100%;
      width: 100%;
      object-position: center;
      object-fit: contain;
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &__toggle {
    display: none;
    height: 1em;
    width: 1em;
    font-size: 44px;
    color: #fff;
    align-items: center;
    justify-content: center;
  }
  &-nav {
    display: flex;
    align-items: center;
    gap: 16px;

    &__item {
      font-family: "SpaceMono", sans-serif;
      font-size: 18px;
      line-height: 24px;

      white-space: nowrap;
      position: relative;
      padding: 10px 12px;
      color: #fff;
      border-radius: 10px;
      text-transform: uppercase;
      transition: all 300ms;

      &:hover {
        background: rgba(#fff, 0.1);
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 60px;
    padding: 1px;
    background: linear-gradient(
      170deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.36) 16.2%,
      rgba(255, 255, 255, 0) 85.55%,
      rgba(255, 255, 255, 0.9) 100%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    pointer-events: none;
  }

  &:not(.header--game) {
    cursor: url("/assets/images/cursor.png"), auto;

    a:hover,
    input:hover,
    button:hover {
    }
  }

  @media (max-width: 1535.9px) {
    &-nav {
      gap: 8px;

      &__item {
        padding: 8px;
      }
    }
  }
  @media (max-width: 1279.9px) {
    border-radius: 0;
    background: transparent;
    backdrop-filter: none;
    padding: 0;

    &-nav {
      display: none;
    }
    &__actions {
      display: none;
    }
    &__toggle {
      display: flex;
    }

    &::before {
      display: none;
    }
  }
}
.header-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background: #050a2c;
  background: rgba(5, 10, 44, 0.7);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  transition: all 300ms;
  display: flex;
  flex-direction: column;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  visibility: hidden;

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 46px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: auto;

    &-item {
      font-size: 24px;
      line-height: 34px;
      justify-content: center;
      white-space: nowrap;
      position: relative;
      padding: 10px;
      color: #fff;
      text-transform: uppercase;
      transition: all 300ms;
    }
  }
  &__toggle {
    display: flex;
    height: 1em;
    width: 1em;
    font-size: 28px;
    color: #fff;
    align-items: center;
    justify-content: center;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;

    & > button {
      width: 100%;
    }
  }

  &.show {
    opacity: 1;
    user-select: auto;
    pointer-events: auto;
    visibility: visible;
  }
}
.header-modal {
  &__heading {
    height: 120px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    pointer-events: none;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__title {
    font-family: "SpaceMono", sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;

    color: #0b2330;
    text-transform: uppercase;
    max-width: 524px;
  }
  &__link {
    font-size: 20px;
    line-height: 32px;

    display: flex;
    width: fit-content;
    color: #0b2330;
    padding: 16px 48px;
    border-radius: 8px;
    background: #f4f4f4;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &-title {
      font-family: "SpaceMono", sans-serif;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;

      color: #0b2330;
      text-transform: uppercase;
    }
  }

  @media (max-width: 767.9px) {
    &__heading {
      height: 72px;
      width: 72px;
    }
    &__title {
      font-size: 20px;
      line-height: 32px;
    }
    &__link {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;

      width: 100%;
      padding: 16px;
    }
    &__bottom {
      gap: 16px;

      &-title {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
}
.header-games {
  position: absolute;
  padding: 24px;
  background: #fff;
  border-radius: 8px;
  top: calc(100% + 20px);
  display: flex;
  align-items: center;
  gap: 24px;
  opacity: 0;
  visibility: hidden;
  user-select: none;
  pointer-events: none;
  transition: all 300ms;
  cursor: auto;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      width: 100px;
      border-radius: 8px;
      background: linear-gradient(360deg, rgba(183, 103, 227, 0.2) 0%, rgba(255, 255, 255, 0) 100%);

      img {
        width: 100%;
        height: 100%;
        max-height: 80px;
        max-width: 80px;
        object-position: center;
        object-fit: contain;
        user-select: none;
        pointer-events: none;
      }
    }
    &-title {
      color: #000;
      font-size: 14px;
      line-height: 18px;
      font-family: "SpaceMono", sans-serif;
      text-transform: uppercase;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 25px;
    height: 20px;
    width: 20px;
    top: -10px;
    transform: rotate(45deg);
    background: #fff;
  }
  &::after {
    content: "";
    position: absolute;
    height: 20px;
    width: 120px;
    top: -20px;
    left: -12px;
  }
}

.header-nav__item:hover > .header-games {
  opacity: 1;
  visibility: visible;
  user-select: auto;
  pointer-events: auto;
}
