// SpaceGrotesk
@font-face {
  font-family: "SpaceGrotesk";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/SpaceGrotesk-Light.ttf") format("TrueType");
}
@font-face {
  font-family: "SpaceGrotesk";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/SpaceGrotesk-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "SpaceGrotesk";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/SpaceGrotesk-Medium.ttf") format("TrueType");
}
@font-face {
  font-family: "SpaceGrotesk";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/fonts/SpaceGrotesk-SemiBold.ttf") format("TrueType");
}
@font-face {
  font-family: "SpaceGrotesk";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/SpaceGrotesk-Bold.ttf") format("TrueType");
}

// SpaceMono
@font-face {
  font-family: "SpaceMono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/SpaceMono-Regular.ttf") format("TrueType");
}
@font-face {
  font-family: "SpaceMono";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/SpaceMono-Bold.ttf") format("TrueType");
}
