.btn {
  font-family: "SpaceMono", sans-serif;
  font-size: 18px;
  line-height: 24px;

  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 60px;
  justify-content: center;
  transition: all 300ms;
  white-space: nowrap;
  text-transform: uppercase;

  .icon {
    flex: none;
  }

  &.reverse {
    flex-direction: row-reverse;
  }
  &.size {
    &-xl {
      gap: 12px;
      padding: 29px 54px;

      .icon {
        font-size: 18px;
      }
    }
    &-lg {
      padding: 23px 21px;
      gap: 16px;

      .icon {
        font-size: 24px;
      }
    }
    &-md {
      gap: 16px;
      padding: 15px 31px;

      .icon {
        font-size: 18px;
      }
    }
    &-sm {
      gap: 10px;
      padding: 11px 31px;

      .icon {
        font-size: 18px;
      }
    }
  }
  &.color {
    &-primary {
      background: #FF5654;
      color: #fff;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);

      &:hover {
        color: #FF5654;
        background: #fff;
      }
    }
    &-secondary {
      background: #0B2330;
      color: #fff;

      &:hover {
        color: #0B2330;
        background: #fff;
        border-color: #0B2330;
      }
    }
    &-dark {
      border-color: rgba(245, 233, 225, 0.3);
      background: #050A2C;
      color: #F5E9E1;

      &:hover {
        color: #050A2C;
        background: #F5E9E1;
      }
    }
    &-white {
      background: #fff;
      color: #131416;

      &:hover {
        color: #fff;
        background: #131416;
      }
    }
    &-green {
      background: #CFFF8F;
      color: #000;
    }

    &-outline-secondary {
      color: #0B2330;
      background: #fff;
      border-color: #0B2330;

      &:hover {
        background: #0B2330;
        color: #fff;
      }
    }
  }

  &.disabled {
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
  }

  @media (max-width: 640px) {
    &.size {
      &-xl {
        gap: 8px;
        padding: 15px;
      }
      &-lg {
        padding: 15px;
        gap: 8px;

        .icon {
          font-size: 18px;
        }
      }
    }
  }
}
