.game {
  &-heading {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;

    header {
      max-width: unset;
    }
  }
  &-menu {
    padding: 16px;
    background: rgba(#fff, 0.08);
    border-radius: 16px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    width: 100%;

    &__profile {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-direction: column;
      gap: 16px;

      &-image {
        width: 100%;
        max-width: 70px;
        border-radius: 50%;
        background: linear-gradient(360deg, rgba(183, 103, 227, 1), rgba(65, 25, 24, 0));
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin: auto;
          position: absolute;
          flex: none;
          width: 80px;
          height: 52px;
          object-position: center;
          object-fit: contain;
        }

        &::before {
          content: "";
          display: flex;
          padding-top: 100%;
        }
      }
      &-title {
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-family: "SpaceMono", sans-serif;
        font-weight: 700;
        line-height: 20px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 16px;
      padding: 2px;
      background: linear-gradient(
          134deg,
          rgba(255, 255, 255, 0.9) 0%,
          rgba(255, 255, 255, 0.36) 16.2%,
          rgba(255, 255, 255, 0) 85.55%,
          rgba(255, 255, 255, 0.9) 100%
        ),
        rgba(255, 255, 255, 0.08);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      pointer-events: none;
    }
  }
  &-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex: 1;

    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 58px;
      width: 58px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);

      img {
        height: 100%;
        width: 100%;
        max-height: 40px;
        max-width: 40px;
        object-position: center;
        object-fit: contain;
      }
    }
    &__content {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: center;
      text-align: center;

      &-title {
        color: #fff;
        font-size: 14px;
        font-family: "SpaceMono", sans-serif;
        font-weight: 700;
        line-height: 18px;
        text-transform: uppercase;
      }
      &-badge {
        padding: 1px 8px;
        color: #000;
        background: #fff;
        border-radius: 50px;
        font-size: 14px;
        font-family: "SpaceMono", sans-serif;
        font-weight: 700;
        line-height: 18px;
        text-transform: uppercase;

        &--red {
          background: #ff5654;
          color: #fff;
        }
        &--green {
          color: #000;
          background: #cfff8f;
        }
      }
    }
    &__button {
      padding: 6px 16px;
      border-radius: 60px;
      background: #fff;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      color: #000;
      font-size: 14px;
      font-family: "SpaceMono", sans-serif;
      line-height: 18px;
      text-transform: uppercase;

      &:disabled {
        color: rgba(#000, 0.3);
      }
    }
  }
  &-reward {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 24px;
    box-shadow: 0 0 0 2px #e2e2e2;
    border-radius: 8px;

    &__logo {
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &__name {
      font-size: 24px;
      font-family: "SpaceMono", sans-serif;
      font-weight: 700;
      line-height: 32px;
      color: #0b2330;
      text-transform: uppercase;
      margin-right: auto;
    }
    &__value {
      color: #0b2330;
      font-size: 24px;
      line-height: 32px;
      font-family: "SpaceMono", sans-serif;
      text-transform: uppercase;
    }
    &__action {
      display: flex;
    }
  }
  &-footer {
    position: absolute;
    bottom: 42px;
    left: 0;
    width: 100%;

    &__form {
      position: relative;
      display: flex;
      align-items: center;
      gap: 24px;
      padding: 16px;
      border-radius: 60px;
      background: rgba(#fff, 0.08);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      max-width: 1264px;

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 60px;
        padding: 1px;
        background: linear-gradient(
          170deg,
          rgba(255, 255, 255, 0.9) 0%,
          rgba(255, 255, 255, 0.36) 16.2%,
          rgba(255, 255, 255, 0) 85.55%,
          rgba(255, 255, 255, 0.9) 100%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        pointer-events: none;
      }
    }
    &__entry {
      flex: 1;
      background: #fff;
      height: 48px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      padding-right: 24px;

      input {
        background: transparent;
        height: 100%;
        flex: 1;
        padding: 0 24px;
        outline: none;
        border-radius: 40px;
        color: #000;
        font-size: 18px;
        line-height: 24px;

        &::placeholder {
          color: rgba(#000, 0.6);
        }
      }
      span {
        color: #000;
        font-size: 14px;
        line-height: 24px;
        opacity: 0.6;
      }
    }
  }
  &-modal {
    &__title {
      font-family: "SpaceMono", sans-serif;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;

      text-transform: uppercase;
      color: #0b2330;
    }
    &__list {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 40px;

      & > button {
        width: 100%;
      }
    }
  }
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    user-select: none;
    pointer-events: none;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  @media (max-width: 1535.9px) {
    &-menu {
      padding: 8px;
      border-radius: 10px;
      gap: 16px;

      &__profile {
        padding: 4px;

        &-image {
          max-width: 72px;
          border-radius: 50%;

          &::before {
            content: "";
            display: flex;
            padding-top: 100%;
          }
        }
        &-title {
          display: none;
        }
      }
    }
    &-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 6px;
      flex: 1;
      min-height: 70px;

      &__image {
        height: 42px;
        width: 42px;

        img {
          max-height: 24px;
          max-width: 24px;
        }
      }
    }
  }
  @media (max-width: 1279.9px) {
    &-heading {
      top: 24px;
    }
  }
  @media (max-width: 1023.9px) {
    &-modal {
      &__title {
        font-size: 20px;
        line-height: 32px;
      }
      &__actions {
        flex-direction: column;
        gap: 12px;
      }
    }
    &-menu {
      padding: 6px;
      border-radius: 6px;

      &__profile {
        &-image {
          max-width: 50px;

          img {
            width: 42px;
            height: 30px;
          }

          &::before {
            content: "";
            display: flex;
            padding-top: 100%;
          }
        }
        &-title {
          display: none;
        }
      }

      &::before {
        border-radius: 6px;
        padding: 1px;
      }
    }
    &-card {
      &__image {
        height: 32px;
        width: 32px;

        img {
          max-height: 18px;
          max-width: 18px;
        }
      }
      &__content {
        display: flex;
        align-items: center;
        gap: 2px;
        justify-content: center;
        text-align: center;
        flex-direction: column;

        &-title {
          font-size: 9px;
          line-height: 12px;
          white-space: nowrap;
        }
        &-badge {
          padding: 1px 4px;
          border-radius: 20px;
          font-size: 10px;
          line-height: 12px;
        }
      }
      &__button {
        padding: 3px 6px;
        border-radius: 26px;
        font-size: 6px;
        line-height: 8px;
      }
    }
  }
  @media (max-width: 767.9px) {
    &-footer {
      bottom: 24px;

      &__form {
        gap: 12px;

        & > button {
          padding: 12px 16px !important;
        }
      }
      &__entry {
        padding-right: 8px;
        height: 48px;

        input {
          font-size: 16px;
          line-height: 24px;
          padding: 0 8px;
          width: 130px;
        }

        span {
          font-size: 12px;
          line-height: 24px;
          white-space: nowrap;
        }
      }
    }
    &-reward {
      display: grid;
      padding: 16px;
      grid-template-columns: 48px 1fr;

      &__logo {
        grid-area: 1 / span 1;
      }
      &__name {
        font-size: 20px;
        line-height: 24px;
        grid-area: 1 / span 4;
      }
      &__value {
        grid-area: 2 / span 2;
      }
      &__action {
        grid-area: 2 / span 3;
      }
    }
  }
}
